<template>
  <div class="register left-float-top ml-30">
    <div class="flex-1">
      <div style="width: 100%;">
        <div  style="margin-top: 6px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">
          <span style="margin-left:20px;font-size: 24px;font-family: Microsoft YaHei;font-weight: bold;color: #333333;">{{course.name}}</span>
        </div>
        <div class="fontsTitle"> <span style="margin-left: 20px;">课程负责人 ：{{course.leader}}</span><span style="margin-left: 20px;">难易程度 ：{{ course.difficultyName }}</span></div>
        <div class="fontsTitle"> <span style="margin-left: 20px;">创建时间 ：{{course.ctime}}</span><span style="margin-left: 20px;">观看次数 ：{{course.likeTimes}}</span></div>
        <div class="fontsTitle"> <span style="margin-left: 20px;">简介：{{course.synopsis}}</span></div>
        <el-row style="margin-top: 10px;">
          <el-col :span="18">
            <div style="margin-top: 40px;" v-if="courseware.source == 'NATIVE'">
              <video-player ref="videoPlayer" class="vjs-custom-skin"
                            :options="options"
                            @pause="onPause($event)"
                            @play="onPlay($event)"
                            @timeupdate="onTimeupdate($event)"
                            @fullscreenchange="onFullscreenChange($event)"
                            @stateChangeed="onStateChange($event)"
                            @ended="onEnded($event)"
                            @resize="onResize($event)"></video-player>
            </div>
            <div style="margin-top: 40px;" v-else>
              <div @click="openPlayer">
                <div :style="'background-image: url('+courseware.coverPath.url+');no-repeat center top;background-size: 100% 100%; height: 527px;border-radius: 10px 10px 0 0;cursor: pointer;text-align: center'">
                  <img style="margin-top: 220px" src="../../assets/img/play.png">
                </div>
<!--                <div style="background-image: url('../../assets/img/play.png');width: 66px; height: 66px;left: 40%; margin-top: 52px;cursor: pointer;"></div>-->

              </div>

            </div>
          </el-col>
          <el-col :span="6">
            <el-tabs style="margin-left: 20px;" v-model="activeName" @tab-click="handleClick" >
              <el-tab-pane label="课程列表" name="first">
                <div v-for="(item, index) in course.resources" style="margin-top: 30px;">
                  <div  @click="changeResources(item.id,index)" :class="item.id == currId  ? 'highlight':''">
                    <img src="../../assets/img/video-list.png" style="height:17px;width:17px;margin-right: 20px;">
                    <span style="font-size: 16px;font-family: Microsoft YaHei;font-weight: 400;color: #333333;cursor: pointer;">{{ item.title }}</span>
                  </div>

                </div>

              </el-tab-pane>
            </el-tabs>
          </el-col>
        </el-row>
      </div>
      <div id="chapters" class="position-absolute" style="display:none;top:52px; width: 20%;right:5px;height: calc(100%);">

      </div>
    </div>
  </div>
</template>

<script>
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
export default {
  name: "Player",
  components: { videoPlayer },
  data(){
    return{
      appName:'',
      activeName:'first',
      id : '',     //ID
      course:{},//课程
      courseware:{},//课件
      currId:'',
      videoUrl:'',
      options: {
        playbackRates: [1.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        aspectRatio: '16:9',
        sources: [{
          type: "video/mp4",  // 类型
          src: ''             // url地址
        }],
        notSupportedMessage: '此视频暂无法播放，请稍后再试.', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        width: document.documentElement.clientWidth || document.body.clientWidth,
        height: (document.documentElement.clientHeight || document.body.clientHeight) - 50,
        flash: { hls: { withCredentials: false }},
        html5: { hls: { withCredentials: false }},
        poster: ''
      },
    }
  },
  mounted() {
    const self=this;
    self.id = this.$route.query.id;
    self.loadCourse();
    self.addCourseLiketime()
  },
  methods: {

    handleClick:function (){

    },
    //添加课程观看次数=
    addCourseLiketime:function(){
      const self = this;
      self.$axios.post('/api/admin/course/'+self.id+'/add/liketime').then(function(res){
        if(res.msg.flag == 0){

        }else{
          console.info(res.msg.message);
        }
      },function(res){
        console.info(res);
      });
    },

    //加载课程信息
    loadCourse:function(){
      const self = this;
      self.$axios.get('/api/admin/course/'+self.id).then(function(res){
        if(res.msg.flag == 0){
          if(null == res.biz || res.biz.empty){
            self.course={};
          }else{
            self.course=res.biz;
            self.currId=res.biz.resources[0].id;
            self.loadCourseware(self.course.resources[0].id);
          }
        }else{
          console.info(res.msg.message);
        }
      },function(res){
        console.info(res);
      });
    },
    //添加课件观看次数=
    addCoursewareLiketime:function(id){
      const self = this;
      self.$axios.post('/api/admin/courseware/'+id+'/add/liketime').then(function(res){
        if(res.msg.flag == 0){

        }else{
          console.info(res.msg.message);
        }
      },function(res){
        console.info(res);
      });
    },
    //加载课件信息
    loadCourseware:function(id){
      const self = this;
      console.log(id,222)
      self.$axios.get('/api/admin/courseware/'+id).then(function(res){
        if(res.msg.flag == 0){
          if(null == res.biz || res.biz.empty){
            self.courseware={};
          }else{
            self.courseware=res.biz;
            self.options.sources[0].src=self.courseware.videoUrl;
            self.addCoursewareLiketime(id)
          }
        }else{
          console.info(res.msg.message);
        }
      },function(res){
        console.info(res);
      });
    },
    //切换课件
    changeResources:function (id,index){
      const self=this;
      self.course.resources.forEach(function (item,index){
        if(id == item.id){
          self.currId=item.id;
        }
      })

      this.loadCourseware(id)
    },
    openPlayer:function (){
      window.open(this.courseware.videoUrl);
    },
    onEnded:function(){
      const self = this;
    },

    onStateChange: function (e) {
    },

    onPause: function (e) {

    },
    onPlay: function (e) {
      const self = this;

    },
    onFullscreenChange: function (e) {
    },
    onTimeupdate: function (e) {
      let self = this;


    },
    onResize:function (e) {
    }
  }
}
</script>

<style scoped>
.fontsTitle{
  margin-top: 10px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}

.highlight {
  background-color: #F9F9F9;
}

</style>